<template>
  <div class="aboutus">
    <!-- First section starts here -->

    <div class="section-container">
      <div class="columns image">
        <img src="@/assets/images/food/image2.jpeg" alt="" />&nbsp;
      </div>
      <div class="columns content">
        <div class="content-container">
          <img src="@/assets/images/interior/logo-transparent.png" alt="" style="width: 200px" />&nbsp;
          <ul>
            <li>Ponedeljak - Četvrtak: <span>09:00h - 00:00h</span></li>
            <li>Petak - Subota: <span>09:00h - 03:00h</span></li>
            <li>Nedelja: <span>09:00h - 00:00h</span></li>
            <p>Rezervišite vaše mesto na vreme!</p>
          </ul>
          <router-link :to="{ name: 'kontakt' }"><my-button class="btn">Rezerviši</my-button></router-link>
        </div>
      </div>
    </div>

    <!-- Second section starts here -->

    <div class="section-container">
      <div class="columns content">
        <div class="content-container">
          <img src="@/assets/images/interior/logo-transparent.png" alt="" style="height: 200px" />&nbsp;
          <h2>Sve počinje ovde!</h2>
          <p>
            Neka ovo bude početak vaše i naše lepe priče, jer sve lepe stvari u
            životu ne poznaju kraj.
          </p>
          <router-link :to="{ name: 'o nama' }">
            <my-button class="btn">O nama</my-button></router-link>
        </div>
      </div>
      <div class="columns image">
        <img src="@/assets/images/background/image1.jpeg" alt="" />&nbsp;
      </div>
    </div>

    <!-- Third section starts here -->

    <div class="section-container">
      <div class="columns image">
        <img src="@/assets/images/interior/monami5.jpg" alt="" />
        &nbsp;
      </div>
      <div class="columns content">
        <div class="content-container">
          <img src="@/assets/images/interior/logo-transparent.png" alt="" style="height: 200px" />&nbsp;
          <h2>Ambijent</h2>
          <p>
            Biser Dunava na Ribarskom Ostrvu u Novom Sadu, jedan od najlepših
            plutajućih restorana u Srbiji i regionu, svojim dizajnom,
            elegancijom i luksuzom ostaje u pamćenju naših gostiju.
          </p>
          <router-link :to="{ name: 'enterijer' }">
            <my-button class="btn">Galerija</my-button></router-link>
        </div>
      </div>
    </div>

    <!--Fourth section starts here -->

    <div class="section-container">
      <div class="columns content">
        <div class="content-container">
          <img src="@/assets/images/interior/logo-transparent.png" alt="" style="height: 200px" />&nbsp;
          <h2>Klub</h2>
          <p>
            Za više informacija o klubu i noćnom provodu posetite nas i
            zapratite na društvenim mrežama!
          </p>
          <Socialmedia />
        </div>
      </div>
      <div class="columns image">
        <img src="@/assets/images/opening/image2.jpeg" alt="" />&nbsp;
      </div>
    </div>

    <!--Fifth section starts here -->

    <div class="section-container">
      <div class="columns image">
        <img src="@/assets/images/cocktails/Mon-Ami0802.jpg" alt="" />&nbsp;
      </div>
      <div class="columns content">
        <div class="content-container">
          <img src="@/assets/images/interior/logo-transparent.png" alt="" style="height: 200px" />&nbsp;
          <h2>Uživajte u našim koktelima</h2>
          <p>
            Rezervišite Vaše mesto na najatraktivnijem kutku našeg lokala bazen,
            namenjen je isključivo našim gostima!
          </p>
          <router-link :to="{ name: 'kontakt' }"><my-button class="btn">Rezerviši</my-button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "../layout/MyButton.vue";
import Socialmedia from "../layout/Socialmedia.vue";

export default {
  components: {
    MyButton,
    Socialmedia,
  },
};
</script>

<style lang="scss">
.aboutus {
  text-align: center;
}

.gallery {
  background-image: url("@/assets/images/background/background-dark.png");
}
</style>
