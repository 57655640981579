<template>
  <b-navbar
    v-on-clickaway="clickedAway"
    toggleable="lg"
    class="w-100"
    :class="{ onScroll: !view.topOfPage }"
  >
    <b-navbar-brand to="/">
      <img
        class="img-logo"
        src="@/assets/images/interior/logo-3-transparent.png"
        alt=""
      />&nbsp;
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" class="">
      <span class="when-opened">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </span>
      <span class="when-closed">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </span>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" ref="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-dropdown-item to="/">Početna</b-dropdown-item>
        <b-dropdown-item to="/o-nama">O nama</b-dropdown-item>
        <b-dropdown-item to="/meni">Jelovnik</b-dropdown-item>
        <b-dropdown-item to="/winelist">Vinska karta</b-dropdown-item>
        <!-- <b-dropdown-item to="/dogadjaji">Dogadjaji</b-dropdown-item> -->
        <b-dropdown-item to="/galerija">Galerija</b-dropdown-item>
        <b-dropdown-item class="reserv" to="/kontakt"
          >Rezervacije</b-dropdown-item
        >
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  data() {
    return {
      view: {
        topOfPage: true,
      },
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    clickedAway() {
      if (this.$refs["nav-collapse"].show)
        this.$root.$emit("bv::toggle::collapse", "nav-collapse");
    },
  },
};
</script>

<style lang="scss"></style>
