import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
export { default as MyButton } from "../components/layout/MyButton.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/o-nama",
    name: "o nama",

    component: () => import("../views/About.vue"),
  },
  {
    path: "/meni",
    name: "meni",
    component: () => import("../views/Menu.vue"),
  },
  {
    path: "/winelist",
    name: "winelist",
    component: () => import("../views/WineList.vue"),
  },

  {
    path: "/galerija",
    name: "galerija",
    component: () => import("../views/Gallery/Gallery.vue"),
  },
  {
    path: "/gastronomija",
    name: "gastronomija",
    component: () => import("../views/Gallery/Gastronomy.vue"),
  },
  {
    path: "/enterijer",
    name: "enterijer",
    component: () => import("../views/Gallery/Interior.vue"),
  },
  {
    path: "/otvaranje",
    name: "otvaranje",
    component: () => import("../views/Gallery/Opening.vue"),
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: () => import("../views/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  hash: false,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    }
    if (to.hash) {
      return {
        el: to.hash + 50,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router;
