<template>
  <div id="app">
    <Topnav />
    <Header />
    <router-view />
    <Footer />
    <ScrollUp />
  </div>
</template>
<script>
import Topnav from "./components/layout/Topnav.vue";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import ScrollUp from "./components/layout/ScrollUp.vue";

export default {
  components: {
    Header,
    Topnav,
    Footer,
    ScrollUp,
  },
};
</script>
<style scooped lang="scss">
@import "@/assets/styles/index.scss";
@import "~bootstrap/scss/bootstrap";

#app {
  @font-face {
    font-family: "CarotDisplay";
    src: url(fonts/CarotDisplay-ExtraLight.otf) format(truetype);
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: "CarotDisplay";
  color: #fff;
}
</style>
