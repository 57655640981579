<template>
  <div>
    <button class="button">
      <div class="line line line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "Button",
};
</script>

<style lang="scss">

</style>
