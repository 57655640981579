<template>
  <div>
    <scroll-up :scroll-duration="1000" :scroll-y="250"> Up </scroll-up>
  </div>
</template>

<script>
import ScrollUp from "vue-scroll-up";
export default {
  components: {
    ScrollUp,
  },
};
</script>

<style></style>
