<template>
  <div class="wraper">
    <ul class="social-icons icon-circle list-unstyled list-inline">
      <li>
        <a href="https://www.facebook.com/monami.marineclub/" target="_blank">
          <font-awesome-icon
            class="fa-facebook"
            icon="fa-brands fa-facebook-square"
        /></a>
      </li>
      <li>
        <a
          href="https://instagram.com/monami_marineclub?igshid=YmMyMTA2M2Y="
          target="_blank"
          ><font-awesome-icon
            class="fa-instagram"
            icon="fa-brands fa-instagram"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
