<template>
  <div class="">
    <vueper-slides
      fade
      autoplay
      lazy
      lazy-load-on-drag
      class="no-shadow vuper"
      fixed-height="600px"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        :title="slide.title"
        :content="slide.content"
      />
    </vueper-slides>
    <Aboutus />
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Aboutus from "../components/home/Aboutus.vue";

export default {
  components: { VueperSlides, VueperSlide, Aboutus },

  data() {
    return {
      slides: [
            {
          title: "Mon-Ami",
          content: "Marine Club and Restaurant",
          image: require("@/assets/images/interior/Mon-Ami5.jpg"),
        },
        {
          title: "Mon-Ami",
          content: "Marine Club and Restaurant",
          image: require("@/assets/images/opening/image5.jpeg"),
        },

        {
          title: "Mon-Ami",
          content: "Marine Club and Restaurant",
          image: require("@/assets/images/interior/Mon-Ami19.jpg"),
        },
      ],
    };
  },
};
</script>

<style>

</style>
