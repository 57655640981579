<template>
  <div class="footer-basic">
    <footer>
      <div class="social">
        <a href="https://www.facebook.com/monami.marineclub/" target="_blank">
          <font-awesome-icon
            class="fa-facebook"
            icon="fa-brands fa-facebook-square"
        /></a>
        <a
          href="https://instagram.com/monami_marineclub?igshid=YmMyMTA2M2Y="
          target="_blank"
          ><font-awesome-icon
            class="fa-instagram"
            icon="fa-brands fa-instagram"
        /></a>
      </div>
      <b-navbar-nav
        class="links ml-auto d-flex flex-row justify-content-center"
      >
        <b-dropdown-item to="/meni">Jelovnik</b-dropdown-item>
        <b-dropdown-item to="/winelist">Vinska Karta</b-dropdown-item>
        <b-dropdown-item class="reserv" to="/kontakt"
          >Rezervacije</b-dropdown-item
        >
      </b-navbar-nav>
      <p class="copyright">
        Mon Ami Club and Restaurant © 2022 <span>powered by </span>
        <a
          class="text-primary"
          href=" https://www.instagram.com/invites/contact/?i=1i4nk4roevh82&utm_content=3glab2"
          target="_blank"
          >MJcoding</a
        >
      </p>
    </footer>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss"></style>
