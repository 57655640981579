<template>
  <div id="top">
    <div class="row topbar" :class="{ 'topbar--hidden': !showTopbar }">
      <div class="col-6 address">
        <address>
          Ribarsko ostrvo bb, Novi Sad
          <a href="+381 66 815 50 60">+381 66 815 50 60</a>
        </address>
      </div>
      
      <div class="col-6">
        <Socialmedia />
      </div>
    </div>
  </div>
</template>

<script>
import Socialmedia from "../layout/Socialmedia.vue";

export default {
  components: { Socialmedia },

  data() {
    return {
      showTopbar: true,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition < 0) {
        return;
      }

      this.showTopbar = currentScrollPosition < this.lastScrollPosition;

      this.lastScrollPosition = currentScrollPosition;
    },
  },
  onScroll() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition < 0) {
      return;
    }

    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
      return;
    }
    this.showNavbar = currentScrollPosition < this.lastScrollPosition;
    this.lastScrollPosition = currentScrollPosition;
  },
};
</script>
<style lang="scss"></style>
